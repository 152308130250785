exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-cdn-content-owners-jsx": () => import("./../../../src/pages/services/cdn_content_owners.jsx" /* webpackChunkName: "component---src-pages-services-cdn-content-owners-jsx" */),
  "component---src-pages-services-enterprise-dia-jsx": () => import("./../../../src/pages/services/enterprise_dia.jsx" /* webpackChunkName: "component---src-pages-services-enterprise-dia-jsx" */),
  "component---src-pages-services-ethernet-jsx": () => import("./../../../src/pages/services/ethernet.jsx" /* webpackChunkName: "component---src-pages-services-ethernet-jsx" */),
  "component---src-pages-services-ip-transit-jsx": () => import("./../../../src/pages/services/ip_transit.jsx" /* webpackChunkName: "component---src-pages-services-ip-transit-jsx" */),
  "component---src-pages-services-remote-peering-cloudconnect-jsx": () => import("./../../../src/pages/services/remote_peering_cloudconnect.jsx" /* webpackChunkName: "component---src-pages-services-remote-peering-cloudconnect-jsx" */),
  "component---src-pages-services-ultra-low-latency-jsx": () => import("./../../../src/pages/services/ultra_low_latency.jsx" /* webpackChunkName: "component---src-pages-services-ultra-low-latency-jsx" */),
  "component---src-pages-services-wavelengths-jsx": () => import("./../../../src/pages/services/wavelengths.jsx" /* webpackChunkName: "component---src-pages-services-wavelengths-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms_conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */)
}

